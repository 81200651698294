<template>
  <span hidden="true">{{ currentUpdateId }}</span>
  <p
    v-if="!ifProfilesSet"
    class="text-center text-lg pt-5"
  >
    Please, select the profiles you'd like to optimise in the <router-link :to="{name: 'profiles'}">
      profiles
    </router-link> section
  </p>
  <p
    v-else-if="ifProfilesSet && !accountDownloaded"
    class="text-center text-lg pt-5"
  >
    We are currently uploading the last 60 days of data in your account. Depending on the number of profiles to be added, the process could take a few hours. We will send you an email once the download is complete!
  </p>
  <p
    v-else
    class="text-center text-lg pt-5"
  >
    In order to see your data, please select an
    <span class="font-bold">Amazon Ads Account</span> in the top bar.
  </p>
</template>

<script>

export default {
  inject: ['reportStatus'],
  props: {
    downloadState: {
      type: Object,
      required: true,
    },
    isProfilesSet: {
      type: Boolean,
      required: true,
    },
    updateId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentDownloadState: this.downloadState,
      currentUpdateId: this.updateId,
      ifProfilesSet: this.isProfilesSet,
    };
  },
  computed: {
    accountDownloaded() {
      return this.currentDownloadState.firstTimeDownloaded;
    },
  },
  watch: {
    updateId: {
      handler(val) {
        this.currentUpdateId = val;
      },
    },
    downloadState: {
      handler(val) {
        this.currentDownloadState = val;
      },
    },
    isProfilesSet: {
      handler(val) {
        this.ifProfilesSet = val;
      },
    },
  },
};
</script>
