<template>
  <span hidden="true">{{ currentUpdateId }}</span>
  <template v-if="plan === null && pricing.status === 'TRIAL'">
    <Message
      v-if="accountTrialState === 'LESS_THAN_ONE_DAY'"
      severity="info"
      :closable="true"
      :sticky="true"
    >
      This free trial will end in one day. Feel free to select the <router-link
        :to="{ name: 'subscribe', params: query }"
      >
        plan
      </router-link> that best suits your needs before it expires!
    </Message>
    <Message
      v-if="accountTrialState === 'LESS_THAN_SEVEN_DAYS'"
      severity="info"
      :closable="true"
      :sticky="true"
    >
      This free trial will end in seven days. Feel free to select the <router-link
        :to="{ name: 'subscribe', params: query }"
      >
        plan
      </router-link> that best suits your needs before it expires!
    </Message>
  </template>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  inject: ['reportStatus', 'query'],
  props: {
    trialState: {
      type: String,
      required: true,
    },
    updateId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentTrialState: this.trialState,
      currentUpdateId: this.updateId,
    };
  },
  computed: {
    accountTrialState() {
      return this.currentTrialState;
    },
    ...mapGetters([
      'plan', 'pricing',
    ]),
  },
  watch: {
    updateId: {
      handler(val) {
        this.currentUpdateId = val;
      },
    },
    trialState: {
      handler(val) {
        this.currentTrialState = val;
      },
    },
  },
};
</script>
