<template>
  <div
    v-if="!loading"
    :class="containerClass"
    @click="onWrapperClick"
  >
    <AppTopBar
      :refresh-counter="refreshCounter"
    />

    <DashboardMenu :hidden="hideDashboardMenu">
      <AppMenu
        :model="menu"
        @menuitem-click="onMenuItemClick"
      />
    </DashboardMenu>

    <div :class="[hideDashboardMenu ? 'layout-simple-container' : 'layout-main-container']">
      <DashboardMainLayout
        @clear-selected-profile="onClearSelectedProfile"
        @change-menu-layout="onChangeMenuLayout"
      />
      <AppFooter />
    </div>

    <transition name="layout-mask">
      <div
        v-if="mobileMenuActive"
        class="layout-mask p-component-overlay"
      />
    </transition>
  </div>
  <div
    v-else
    class="text-center pt-5"
  >
    <ProgressSpinner />
    <Toast />
  </div>
</template>

<script>
import { computed } from 'vue';
import { mapMutations, mapGetters } from 'vuex';
import AppTopBar from '../AppTopbar.vue';
import AppMenu from '../AppMenu.vue';
import AppFooter from '../AppFooter.vue';
import DashboardMenu from '../components/dashboard/DashboardMenu';
import DashboardMainLayout from '../components/dashboard/DashboardMainLayout.vue';
import A2CAClient from '../api/a2caAuthorizedClient';
import { getDefaultMenu, getProfilesOnlyMenu } from '../constants/menuConstants';
import hasProfilesEnabled, { isAccountEnabled } from '../utils/accountUtils';
import { setUser } from '../monitoring';

export default {
  components: {
    AppTopBar,
    AppMenu,
    AppFooter,
    DashboardMenu,
    DashboardMainLayout,
  },
  provide() {
    return {
      reportStatus: computed(() => this.reportStatus),
      query: this.$route.query,
    };
  },
  emits: ['change-theme'],
  data() {
    return {
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      reportStatus: null,
      loading: true,
      menu: getDefaultMenu(this.$route.query),
      refreshCounter: 0,
      hideDashboardMenu: false,
      // eslint-disable-next-line vue/no-unused-properties
    };
  },
  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive':
            this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active':
            this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
        },
      ];
    },
    ...mapGetters(['selectedProfile']),
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
    async selectedProfile(val) {
      // get previous reports status on selected profile change
      if (val !== null) {
        this.loading = true;
        const client = new A2CAClient(this.$route.query);
        const reports = await client.getReportsStatus(this.selectedProfile.profileId, this.selectedProfile.region);
        this.reportStatus = reports;
        this.loading = false;
      }
    },
  },
  async beforeUpdate() {
    if (this.mobileMenuActive) this.addClass(document.body, 'body-overflow-hidden');
    else this.removeClass(document.body, 'body-overflow-hidden');
    if (this.$route.path === '/subscribe') {
      this.hideDashboardMenu = true;
    } else {
      this.hideDashboardMenu = false;
    }

    const client = new A2CAClient(this.$route.query);
    const accountRequest = client.getCurrentAccount();

    const [account] = await Promise.all([accountRequest]);

    this.setAccount(account);
    setUser(account.account);
  },
  async beforeMount() {
    this.loading = true;
    const client = new A2CAClient(this.$route.query);
    const accountRequest = client.getCurrentAccount();

    const [account] = await Promise.all([accountRequest]);

    this.setAccount(account);
    if (hasProfilesEnabled(account.account.enabledProfiles)) {
      this.menu = getDefaultMenu(this.$route.query);
    } else {
      this.menu = getProfilesOnlyMenu(this.$route.query);
    }

    if (!isAccountEnabled(account.account)) {
      this.$router.push({ name: 'subscribe', query: this.$route.query });
      this.hideDashboardMenu = true;
    }
    if (this.$route.path === '/subscribe') {
      this.hideDashboardMenu = true;
    } else {
      this.hideDashboardMenu = false;
    }

    this.loading = false;
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },

    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },

    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      // eslint-disable-next-line no-param-reassign
      else element.className += ` ${className}`;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else {
        // eslint-disable-next-line no-param-reassign
        element.className = element.className.replace(
          new RegExp(
            `(^|\\b)${className.split(' ').join('|')}(\\b|$)`,
            'gi',
          ),
          ' ',
        );
      }
    },
    onClearSelectedProfile() {
      this.refreshCounter += 1;
    },
    onChangeMenuLayout(layout) {
      this.menu = layout;
    },
    ...mapMutations(['setAccount']),
  },
};
</script>
<style scoped lang="scss">
  .layout-simple-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 7rem 2rem 2rem 2rem;
    justify-content: space-between;
  }
</style>
