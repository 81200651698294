<template>
  <span hidden="true">{{ currentUpdateId }}</span>
  <Message
    v-if="adSpendPercentage !== null && adSpendPercentage >= 100"
    severity="info"
    :closable="true"
    :sticky="true"
  >
    The monthly ad spend has reached the allowed limit for the current plan. To maintain uninterrupted bid optimization during this period, consider <router-link :to="{name: 'subscribe', query}">
      upgrading your plan
    </router-link>. Otherwise, bid optimization will resume on the next renewal.
  </Message>
  <Message
    v-else-if="adSpendPercentage !== null && adSpendPercentage > 80"
    severity="info"
    :closable="true"
    :sticky="true"
  >
    Your monthly ad spend has surpassed 80% of your plan's total limit. To maintain uninterrupted bid optimization during this period, consider <router-link :to="{name: 'subscribe', query}">
      upgrading your plan
    </router-link>. Otherwise, bid optimization will resume on the next renewal.
  </Message>
  <Message
    v-else-if="adSpendPercentage !== null && adSpendPercentage > 75"
    severity="info"
    :closable="true"
    :sticky="true"
  >
    Your monthly ad spend is approaching 80% of your plan's total limit. To maintain uninterrupted bid optimization during this period, consider <router-link :to="{name: 'subscribe', query}">
      upgrading your plan
    </router-link>. Otherwise, bid optimization will resume on the next renewal.
  </Message>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  inject: ['reportStatus'],
  props: {
    updateId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentUpdateId: this.updateId,
    };
  },
  computed: {
    ...mapGetters([
      'adSpendPercentage',
    ]),
  },
  watch: {
    updateId: {
      handler(val) {
        this.currentUpdateId = val;
      },
    },
  },
};
</script>
