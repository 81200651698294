<template>
  <span class="brand-text"><span class="brand-first-name">Books</span><span class="brand-second-name">Flyer</span></span>
</template>
<script>
export default {

};
</script>
<style scoped>
    .brand-text {
        font-family: Helvetica, sans-serif;
        font-size: 2rem;
    }
    .brand-first-name {
        color: #6465F1;
    }
    .brand-second-name {
        color: #41B883;
    }
</style>
