<template>
  <div class="layout-main">
    <template v-if=" $route.path.startsWith('/subscribe') || $route.path.startsWith('/account/info') || $route.path.startsWith('/account/billing')">
      <!-- Pages that do not requiere a linked account, like subscribe -->
      <router-view
        :selected-profile="selectedProfile"
        @clear-selected-profile="onClearSelectedProfile"
        @change-menu-layout="onChangeMenuLayout"
      />
    </template>
    <p
      v-else-if="!accountLinked"
      class="text-center"
    >
      In order to see your data, you must
      <a
        href="#"
        @click="loginWithAmazonHandler"
      >log in with Amazon</a>.
    </p>
    <template
      v-else-if="$route.path === '/settings/profiles' || $route.path.startsWith('/admin') || $route.path.startsWith('/account')"
    >
      <!-- Pages that do not requiere a selected profile, like account settings -->
      <router-view
        :selected-profile="selectedProfile"
        @clear-selected-profile="onClearSelectedProfile"
        @change-menu-layout="onChangeMenuLayout"
      />
    </template>
    <template v-else-if="selectedProfile === null">
      <DashboardAdSpendMessage
        :update-id="updateId"
      />
      <DashboardTrialMessage
        v-if="trialState !== null"
        :trial-state="trialState"
        :update-id="updateId"
      />
      <DashboardAccountMessage
        v-if="downloadState !== null"
        :download-state="downloadState"
        :is-profiles-set="numberOfEnabledProfiles > 0"
        :update-id="updateId"
      />
    </template>
    <template v-else>
      <DashboardAdSpendMessage
        :update-id="updateId"
      />
      <DashboardTrialMessage
        v-if="trialState !== null"
        :trial-state="trialState"
        :update-id="updateId"
      />
      <DashboardProfileBanner
        v-if="downloadState !== null && profileMetricsCount !== null"
        :download-state="downloadState"
        :update-id="updateId"
        :profile-metrics-count="profileMetricsCount"
      />
      <router-view
        :key="$route.fullPath"
        :selected-profile="selectedProfile"
        @clear-selected-profile="onClearSelectedProfile"
        @change-menu-layout="onChangeMenuLayout"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardAccountMessage from './DashboardAccountMessage.vue';
import DashboardProfileBanner from './DashboardProfileBanner.vue';
import DashboardAdSpendMessage from './DashboardAdSpendMessage.vue';
import DashboardTrialMessage from './DashboardTrialMessage.vue';
import A2CAAuthClient from '../../api/a2caAuthorizedClient';
import A2CAClient from '../../api/a2caClient';
import { LWA_CANCELLED, SHOW_PAYMENT_SUCCESS_BANNER } from '../../constants/storageConstants';

export default {
  components: {
    DashboardAccountMessage, DashboardProfileBanner, DashboardAdSpendMessage, DashboardTrialMessage,
  },
  inject: ['query'],
  emits: ['clear-selected-profile', 'change-menu-layout'],
  data() {
    return {
      downloadState: null,
      trialState: null,
      profileMetricsCount: null,
      updateId: 0,
      stateInterval: null,
    };
  },

  computed: {
    ...mapGetters(['accountLinked', 'selectedProfile', 'userId', 'numberOfEnabledProfiles', 'trialStatus', 'metrics']),
  },
  watch: {
    metrics: {
      handler(val) {
        this.profileMetricsCount = val?.length || 0;
      },
    },
  },
  created() {
    if (JSON.parse(localStorage.getItem(SHOW_PAYMENT_SUCCESS_BANNER) ?? 'false')) {
      this.$toast.add({
        severity: 'success',
        summary: 'Plan updated',
        detail: 'The plan update has been successful!',
        life: 1500,
      });
      localStorage.setItem(SHOW_PAYMENT_SUCCESS_BANNER, JSON.stringify(false));
    }
    if (localStorage.getItem(LWA_CANCELLED)) {
      this.$toast.add({
        severity: 'error',
        summary: 'Could not log in with Amazon',
        detail: `This Amazon Ads account is already linked to the following BooksFlyer account: ${localStorage.getItem(LWA_CANCELLED)}.`,
        life: 3000,
      });
      localStorage.removeItem(LWA_CANCELLED);
    }
  },
  async mounted() {
    const client = new A2CAAuthClient(this.query);
    this.trialState = this.trialStatus;
    if (this.selectedProfile !== null) {
      this.profileMetricsCount = await client.countProfileMetrics(this.selectedProfile.profileId);
    }

    this.downloadState = await client.getDownloadState();

    if (this.stateInterval === null) {
      this.stateInterval = setInterval(async () => {
        this.downloadState = await client.getDownloadState();

        this.trialState = this.trialStatus;
        if (this.selectedProfile !== null) {
          this.profileMetricsCount = await client.countProfileMetrics(this.selectedProfile.profileId);
        }
        this.updateId = Math.random();
      }, 120000);
    }
  },
  beforeUnmount() {
    clearInterval(this.stateInterval);
  },
  methods: {
    onClearSelectedProfile() {
      this.$emit('clear-selected-profile');
    },
    onChangeMenuLayout(layout) {
      this.$emit('change-menu-layout', layout);
    },
    async loginWithAmazonHandler() {
      const client = new A2CAClient();
      window.location = await client.getAuthorizationUrl(this.userId);
    },
  },
};
</script>
