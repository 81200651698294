<template>
  <div
    id="menu-config"
    :class="containerClass"
  >
    <div class="menu-config-content">
      <slot />
    </div>
    <a
      id="menu-config-button"
      href="#"
      class="menu-config-button"
      @click="toggleConfigurator"
    >
      <i :class="menuToggleClass" />
    </a>
    <Button
      class="p-button-danger menu-config-close p-button-rounded p-button-text"
      icon="pi pi-times"
      :style="{ 'z-index': 1 }"
      @click="hideConfigurator"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: true,
    };
  },
  outsideClickListener: null,
  computed: {
    containerClass() {
      return ['menu-config', { 'menu-config-active': this.active }];
    },
    menuToggleClass() {
      return ['pi', this.active ? 'pi-arrow-left' : 'pi-arrow-right'];
    },
  },
  methods: {
    toggleConfigurator(event) {
      this.active = !this.active;
      event.preventDefault();

      if (this.active) this.bindOutsideClickListener();
      else this.unbindOutsideClickListener();
    },
    hideConfigurator(event) {
      this.active = false;
      this.unbindOutsideClickListener();
      event.preventDefault();
    },
    bindOutsideClickListener() {
      if (!this.outsideClickListener) {
        this.outsideClickListener = (event) => {
          if (this.active && this.isOutsideClicked(event)) {
            this.active = false;
          }
        };
        document.addEventListener('click', this.outsideClickListener);
      }
    },
    unbindOutsideClickListener() {
      if (this.outsideClickListener) {
        document.removeEventListener('click', this.outsideClickListener);
        this.outsideClickListener = null;
      }
    },
    isOutsideClicked(event) {
      return !(
        this.$el.isSameNode(event.target) || this.$el.contains(event.target)
      );
    },
  },
};
</script>
