<template>
  <div class="layout-footer">
    <p class="footer-text">
      © Booksflyer 2024
    </p>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  methods: {
    // eslint-disable-next-line vue/no-unused-properties
    footerImage() {
      return 'images/logo.png';
    },
  },
};
</script>
<style scoped>
  .footer-text {
    font-weight: bold;
  }
</style>
