class AccountType {
  constructor(value, name) {
    this.name = name;
    this.value = value;
  }
}

const ACCOUNT_TYPES = [
  new AccountType('ADMIN', 'ADMIN'),
  new AccountType('AGENT', 'Ads Manager'),
  new AccountType('TEST', 'Test account'),
];

const findAccountByType = (type) => ACCOUNT_TYPES.find((acc) => acc.value === type);

export { ACCOUNT_TYPES, findAccountByType };
